import {
  Text,
  Stack,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
  Button,
  Box,
  Image,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { configurationsState } from "../../shared/recoilStates/configurations.state";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { LANDING_PAGE } from "../../shared/constants/endpoints";
import { useRouter } from "next/router";
import { useColor } from "../../shared/hooks/use-color.hook";

import {
  initialEventState,
  newEventState,
} from "../../shared/recoilStates/new-event.state";
import axios from "axios";
import { userDataResponseState } from "../../shared/recoilStates/user-data.state";
import { API_BASE } from "../../shared/constants/apiKeys";
import { textState } from "../../shared/recoilStates/language-selection.state";
import { useToastHook } from "../../shared/hooks/use-toast.hook";
import RegisterationModal from "../../components/Login/RegisterationModal";

const LoginForm = () => {
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const errorToast = useToastHook("error");
  const [config, setConfig] = useRecoilState(configurationsState);
  const router = useRouter();
  const colors = useColor();
  const setNewEvent = useSetRecoilState(newEventState);
  const [userData, setUserData] = useRecoilState(userDataResponseState);
  const successToast = useToastHook("success");
  const language = useRecoilValue(textState);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const onRegister = useCallback(async () => {
    if (!email || !password) {
      return;
    }
    try {
      const response = await axios.post(
        API_BASE + `/auth/login?email=${email}&password=${password}`
      );

      if (response) {
        successToast(language.user_registeration, language.success_toast);
        setUserData({
          ...response.data,
          timestamp: Math.floor(Date.now() / 1000),
          pageSession: userData.pageSession,
        });
        router.push(
          userData.pageSession ? userData.pageSession : LANDING_PAGE,
          undefined,
          {
            shallow: true,
          }
        );
        setConfig({
          ...config,
          newEventLocationProvided: false,
          is_Authenticated: true,
        });
        setNewEvent(initialEventState);
      }
    } catch (e: any) {
      errorToast(language.user_registeration, e.response.data.message);

      throw new Error("Error in user login!", e);
    }
  }, [
    config,
    email,
    errorToast,
    language.success_toast,
    language.user_registeration,
    password,
    router,
    setConfig,
    setNewEvent,
    setUserData,
    successToast,
  ]);

  return (
    <Flex
      w='100%'
      h={780}
      flexDir={{ base: "column", md: "row" }}
    >
      <Box
        w={{ base: "80%", md: "48%" }}
        h={{ base: "55%", md: "60%" }}
        mt={{ base: "30%", md: "10%" }}
      >
        <Stack
          mb={30}
          gap={10}
          w={{ base: "80%", md: "55%" }}
          ml={"20%"}
        >
          <Text
            fontSize={{ base: 20, md: 30 }}
            fontWeight={"bold"}
          >
            {language.user_registeration}
          </Text>

          <Input
            value={email}
            h={{ base: 41, lg: 57 }}
            onChange={(e) => setEmail(e.target.value)}
            boxShadow='4px 4px 10px #D8D8E2'
            placeholder='user@email.com'
            fontSize={{ base: "xs", md: "lg" }}
          />

          <InputGroup>
            <Input
              type={showPassword ? "text" : "password"}
              value={password}
              colorScheme='white'
              h={{ base: 41, lg: 57 }}
              onChange={(e) => setPassword(e.target.value)}
              placeholder='Password'
              fontSize={{ base: "xs", md: "lg" }}
              boxShadow='4px 4px 10px #D8D8E2'
            />
            <InputRightElement>
              <Icon
                _hover={{ color: "red" }}
                cursor='pointer'
                mt={{ base: 2, md: 4 }}
                mr={{ base: 2, md: 6 }}
                fontSize={{ base: "md", md: "2xl" }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <BsEyeSlash /> : <BsEye />}
              </Icon>
            </InputRightElement>
          </InputGroup>
        </Stack>
        <Button
          bg={colors.primaryColor}
          onClick={() => onRegister()}
          mt={4}
          borderRadius='full'
          alignSelf='center'
          w={{ base: "80%", md: "55%" }}
          ml={"20%"}
          h={{ base: "2rem", lg: 57 }}
          _hover={{ bg: colors.textOnHover }}
          color={"white"}
          letterSpacing={"wider"}
          fontSize={{ base: 14, md: 20 }}
          boxShadow='4px 4px 10px #A0A0A5'
        >
          {language.log_in}
        </Button>
        <Text
          mt={8}
          color={colors.primaryColor}
          ml={"20%"}
          textDecoration={"underline"}
          onClick={onOpen}
        >
          Create an account
        </Text>
      </Box>
      <Box
        bgColor='#F2F2F9'
        h={{ base: "45%", md: "100%" }}
        w={{ base: "100%", md: "50%" }}
        ml={{ base: 0, md: 10 }}
      >
        <Image
          borderRadius={"md"}
          src='/images/loginLogo.png'
          ml={{ base: "36%", md: "33%" }}
          mt={"30%"}
          w={{ base: "90px", md: window.innerWidth < 1608 ? "37%" : "32%" }}
          h={{ base: "90px", md: window.innerWidth < 1608 ? "35%" : "38%" }}
        ></Image>
      </Box>
      <RegisterationModal
        isOpen={isOpen}
        onClose={onClose}
      />
    </Flex>
  );
};

export default LoginForm;
