import { atom } from "recoil";
import { EventAttributes } from "../interfaces/EventAttributes";
import { recoilPersist } from "recoil-persist";
import { EventCategories } from "../enums/event-categories.enum";

const { persistAtom } = recoilPersist();
export const initialEventState: EventAttributes = {
  title: "",
  eventEndDateTime: "",
  eventStartDateTime: "",
  created: "",
  modified: "",
  eventImages: [],
  lat: 0,
  long: 0,
  primary_category: EventCategories.UNDEFINED_CATEGORY,
  other_categories: [],
  selectedAddress: "",
  teaser: "",
  description: "",
  averageTicketPrices: undefined,
  averageAge: undefined,
  averageAtmosphere: undefined,
  averageInvolvement: undefined,
  averageSocializingOpportunity: undefined,
  attendeLimit: undefined,
  isIndoor: false,
  isOutdoor: false,
  isPreparedForRain: false,
  locationName: "",
  locationDirections: "",
  averageVolume: undefined,
  hashtags: [],
  foodAvailable: false,
  drinksAvailable: false,
  wheelchairAccessible: false,
  strollerAccessible: false,
  ticketsLink: "",
  ownWebsiteLink: "",
  eventEmail: "",
  eventPhone: "",
  facebookLink: "",
  instagramLink: "",
  googleMapsLink: "",
  is_qualified_data: false,
  twitterLink: "",
  petFriendly: false,
  kidsFriendly: false,
  parkingAvailable: false,
  alcoholAllowed: false,
  publicTransportation: false,
  targetedAudience: [],
  foodVarieties: [],
  source: "",
};
export const newEventState = atom<EventAttributes>({
  key: "newEvent",
  default: initialEventState,
  effects_UNSTABLE: [persistAtom],
});
