/* eslint-disable max-len */
import {
  Button,
  FormLabel,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import React, { FunctionComponent } from 'react'

type Props = {
  isOpen: boolean
  onClose: () => void
}
const RegisterationModal: FunctionComponent<Props> = ({ isOpen, onClose }) => {
  const [email, setEmail] = React.useState('')
  const [name, setName] = React.useState('')

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>User Registration</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text> Contact us to register your personal account</Text>
          <FormLabel mt={8}>User name</FormLabel>
          <Input
            placeholder='Your user name'
            type='text'
            onChange={(e) => {
              setName(e.target.value)
            }}
          />
          <FormLabel mt={4}>Email address</FormLabel>
          <Input
            placeholder='Your Email address'
            type='email'
            onChange={(e) => {
              setEmail(e.target.value)
            }}
          />
        </ModalBody>

        <ModalFooter>
          <Button colorScheme='pink' w={20} mr={5} onClick={onClose}>
            <Link
              href={`mailto:hello@balloon-events.com?subject=Creator%20User%20Registration&body=%0A%0ANew%20user%20registration%20on%20your%20site%20Creator%20webapp:%0A%0A%0AUser%20name:%20${name}%0AEmail%20address:%20${email}`}
            >
              Send
            </Link>
          </Button>
          <Button colorScheme='gray' mr={3} onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default RegisterationModal
